<template>
    <div class="member-relative">
        <div class="relative" v-for="item in list" :key="item.id" @click="changeRela(item)">
            <div class="relative-avater">
                <div class="title">
                    <span class="name">{{item.relativeName}}</span>
                    <span class="phone">{{item.relativeMobile}}</span>
                </div>
                <span class="del" @click.stop="delrelative(item.id)">-</span>
            </div>
            <div class="info horse">
                <div class="label ">亲属关系</div>
                <div class="value">{{item.relativeShipName}}</div>
            </div>
            <!-- <div class="info">
                <div class="label">亲属年龄</div>
                <div class="value">{{item.relativeAge}}</div>
            </div> -->
            <div class="info">
                <div class="label">亲属性别</div>
                <div class="value">{{item.relativeSexName}}</div>
            </div>
             <div class="info">
                <div class="label">亲属身份证</div>
                <div class="value">{{item.relativeIdCard}}</div>
            </div>
            <div class="info">
                <div class="label">亲属民族</div>
                <div class="value">{{item.relativeNation}}</div>
            </div>
            <div class="info">
                <div class="label">亲属出生日期</div>
                <div class="value">{{item.relativeBirthDate}}</div>
            </div>
            <div class="circle left-top"></div>
            <div class="circle right-top"></div>
        </div>

        <van-dialog v-model="showAdd" title="添加亲属" show-cancel-button @confirm="changeRecode" @cancel="onCancelRecode" :before-close="beforeClose">
            <van-cell-group>
                <van-field class="my-cell" center label="亲属关系" readonly @click="showRelative=true" required v-model="relativeShip" placeholder="请选择亲属关系"/> 
                <van-field class="my-cell" center label="亲属名称" required v-model="relativeName" placeholder="请输入亲属名称"/>
                <van-field class="my-cell" center label="亲属性别" required readonly @click="showRelativeSex=true" v-model="relativeSex" placeholder="请选择亲属性别"/>          
                <van-field class="my-cell" center label="亲属手机号" v-model="relativeMobile" placeholder="请输入亲属手机号"/>
                <van-field class="my-cell" center label="亲属身份证"  v-model="relativeIdCard" placeholder="请输入亲属身份证"/>
                <van-field class="my-cell" center label="亲属民族"  v-model="relativeNation" placeholder="请输入亲属民族"/>
                <van-field class="my-cell" center label="亲属出生日期" readonly @click="showDate=true" v-model="relativeBirthDate" placeholder="请选择亲属出生日期"/>
            </van-cell-group>
        </van-dialog>

        <van-popup v-model="showRelative" round position="bottom">
            <van-picker
                title="选择亲属关系"
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showRelative=false"
            />
        </van-popup>

        <van-popup v-model="showRelativeSex" round position="bottom">
            <van-picker
                title="请选择亲属性别"
                show-toolbar
                :columns="columnsSex"
                @confirm="onConfirmSex"
                @cancel="showRelative=false"
            />
        </van-popup>

        <van-popup v-model="showDate" position="bottom" round>
            <van-datetime-picker v-model="currentDate" type="date"  title="选择年月日" @confirm="getDate"
                @cancel="showDate = false"
                :min-date="minDate"
                :max-date="maxDate"
            />
        </van-popup>

        <no-data v-show="list.length === 0"/>
        
        <my-btn @onSubmit="onSubmit" :btn-str="'+添加亲属'"/> 

    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [ ],
            item: {},
            showAdd: false,
            flag: false,
            showRelative: false,
            showRelativeSex: false,
            showDate: false,
            relativeShip: '',
            relativeName: '',
            relativeAge: '',
            relativeMobile: '',
            relativeSex: '',
            relativeIdCard: '' ,
            relativeNation: '' ,
            relativeBirthDate: '',
            columns: [],
            columnsSex: [
                { text: '男', value: 1 },
                { text: '女', value: 2 },
            ],
            form: {},
            minDate: new Date(1950, 1 ,1),
            maxDate: new Date(2050, 12 ,12),
            currentDate: new Date()
           

        }
    },
    methods: {
        onSubmit() {
            this.showAdd = true
        },
        getDate(date) {
            // console.log(new Date(date).FormatShort())
            this.relativeBirthDate = new Date(date).FormatShort()
            this.showDate = false
        },
        onConfirm(data) {
            this.relativeShip = data.text
            this.form.relativeShip = data.value
            this.showRelative = false
        },
        onConfirmSex(data) {
            this.relativeSex = data.text
            this.form.relativeSex = data.value
            this.showRelativeSex = false
        },
        onCancelRecode() {
            this.relativeName = ''
            this.relativeShip = ''
            this.relativeName = ''
            this.relativeAge = ''
            this.relativeMobile = ''
            this.relativeSex = ''
            this.relativeIdCard = '' 
            this.relativeNation = '' 
            this.relativeBirthDate = ''
            this.form = {}
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                if(!this.flag){
                    done(false)
                }else{
                    setTimeout(done, 100);
                }
            } else{
                done()
            }
        },

        //添加亲属卡
        changeRecode() {
            this.form.relativeName = this.relativeName
            this.form.relativeMobile = this.relativeMobile
            this.form.relativeIdCard = this.relativeIdCard
            this.form.relativeNation = this.relativeNation
            this.form.relativeBirthDate = this.relativeBirthDate
            this.form.memberId = this.$route.query.memberId
            if(this.relativeShip === ''){
                this.$help.error("亲属关系不能为空")
                this.flag = false
                return
            }
            if(this.relativeName === ''){
                this.$help.error("亲属名称不能为空")
                this.flag = false
                return
            }
            //console.log(this.form)
            if(!this.form.id){
                this.$api.post('member/base/memberRelative/memberRelative-save', this.form).then(res=>{
                    if(res.data) {
                        this.$help.success('添加成功')
                        this.getrelativeList()
                        this.onCancelRecode()
                        this.showAdd = false
                    }else{
                        this.$help.error(res.msg)
                    }
                })
            }else{
                //修改
                this.$api.post('member/base/memberRelative/memberRelative-update', this.form).then(res=>{
                    if(res.data) {
                        this.$help.success('修改成功')
                        this.getrelativeList()
                        this.onCancelRecode()
                        this.showAdd = false
                    }else{
                        this.$help.error(res.msg)
                    }
                })
            }
            

        },
        //删除亲属卡
        delrelative(id) {
            this.$help.confirm({
                title: '提示',
                message: '是否确定删除',
            })
            .then(() => {
                this.$help.loading()
                this.$api.post('member/base/memberRelative/memberRelative-delete', {id: id}).then(res=>{
                    if(res.data) {
                        this.$help.success('删除成功')
                        this.getrelativeList()
                    }else{
                        this.$help.error(res.msg)
                    }
                })
                .finally(this.$help.loaded)
            })
            .catch(() => {})
        },

        //修改
        changeRela(data) {
            this.showAdd = true
            console.log(data)
            this.relativeShip = data.relativeShipName
            this.relativeName = data.relativeName 
            this.relativeAge = data.relativeAge 
            this.relativeMobile = data.relativeMobile
            this.relativeIdCard = data.relativeIdCard
            this.relativeNation = data.relativeNation
            this.relativeBirthDate = data.relativeBirthDate
            this.relativeSex = data.relativeSexName

            this.form.id = data.id
            this.form.relativeSex = data.relativeSex
            this.form.relativeShip = data.relativeShip
        },

        //
        getrelative() {
            this.$api.post('sys/sysList/sysList-list-byName', {name: '亲属关系'}).then(res=>{
                if(res.length !== 0){
                    res.forEach(ele=>{
                        this.columns.push({
                            text: ele.itemText,
                            value: ele.itemValue
                        })
                    })
                }
            })
        },
        getrelativeList() { 
            this.list = []
            this.$help.loading() 
            this.$api.post('member/base/memberRelative/memberRelative-listForWx', this.$route.query).then(res=>{
                //console.log(res)
                delete res.rootData
                res.map(ele=>{
                    this.list.push(ele)
                })
            })
            .finally(this.$help.loaded)
        }
    },
    mounted() {
        this.getrelativeList()
        this.getrelative()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .member-relative{
        padding-top: 20px;
        padding-bottom: 80px;
        .relative{
            margin: 0 20px 20px;
            box-shadow: 0 0 8px 6px $boxShadow;
            border-radius: 9px;
            padding: 10px;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            &-avater{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px 11px;
                .title{
                    .name{
                        font-size: 16px;
                        color: $main;
                        font-weight: bold;
                        padding-right: 5px;
                    }
                    .phone{
                        color: $gray;
                    }
                }
                .del{
                    background-color: $red;
                    display: inline-block;
                    line-height: 20px;
                    width: 20px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    color: #fff;
                    border-radius: 50%;
                }
                .van-image__error{
                    border-radius: 50%;
                }
                .my-image{
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    border: 1px solid $gray;
                    margin-right: 5px;
                    img{
                        border-radius: 50%;
                    }
                }
            }
            &-code{
                color: $gray;
            }
            .horse{
                padding-top: 10px;
                border-top: 1px dashed $gray;
            }
            .last{
                padding-bottom: 10px;
            }
            .info{
                display: flex;
                justify-content: space-between;
                line-height: 30px;
                padding-left: 10px;
                padding-right: 10px;
                .label{
                    color: $gray;
                }
            } 
            .color{
                .value{
                    color: $main;
                }
            }   
            .bottom{
                padding-top: 9px;
                border-top: 1px dashed $gray;
                .btn{
                    display: flex;
                    justify-content: flex-end;
                    .van-button{
                        background-color: $main;
                        border: 0;
                        padding: 0 30px;
                        color: #fff;
                        height: 30px;
                        margin-right: 5px;
                    }
                }
                .tip{
                    padding: 5px 10px 5px 10px;
                    text-align: center;
                    color: $pink;
                    min-width: 100px;
                    max-width: 180px;
                }
            }
            .circle{
                height: 20px;
                width: 20px;
                position: absolute;
                border-radius: 50%;
                background-color: $boxShadow;
            }
            .left-top{
                top: 35px;
                left: -10px;
            }
            .right-top{
                top: 35px;
                right: -10px;
            }
            .tip-status, .tip-status-success{
                width: 50px;
                border-radius: 50%;
                border: 1px solid $red;
                position: absolute;
                top: -8px;
                right: -8px;
                line-height: 50px;
                text-align: center;
                color: $red;
                transform: rotate(45deg);
                
            }
            .tip-status-success{
                border: 1px solid $green !important;
                color: $green !important;
            }
        }
    }

</style>